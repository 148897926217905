import { Button, DatePicker, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  showDangerMessage,
  showSuccessMessage,
  sortColumnWithNulls,
} from "../../../utils";
import {
  buildNetworks,
  getDecisionMakersForResearchRequests,
  getEASCompanies,
  submitResearchRequests,
} from "../../../APIClient";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
import moment from "moment";
import { useSelector } from "react-redux";
import { formatDecisionMakerQAStatus } from "../QualityAssurance/DecisionMakerStatus";

const RequestsAdminGrid = () => {
  const dateFormat = "YYYY-MM-DD";
  const [decisionMakers, setDecisionMakers] = useState([]);
  const [dueDate, setDueDate] = useState(moment(new Date()).format(dateFormat));
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDecisionMakers, setSelectedDecisionMakers] = useState([]);
  const user = useSelector((state) => state.user);

  const getDecisionMakerGrid = async () => {
    try {
      setIsLoading(true);
      const data = await getDecisionMakersForResearchRequests();
      const organizationData = await getEASCompanies([
        ...new Set(
          data.map((item) => item.company_id).filter((company_id) => company_id)
        ),
      ]);

      setDecisionMakers(
        data.map((x) => {
          return {
            key: x.dm_id,
            ...x,
            ...organizationData.find(
              (organizationItem) => organizationItem.company_id === x.company_id
            ),
          };
        })
      );
      setIsLoading(false);
    } catch (error) {
      showDangerMessage("An error occurred while fetching decision makers");
    }
  };

  useEffect(() => {
    getDecisionMakerGrid();
  }, []);

  const columns = [
    {
      title: "DM id",
      dataIndex: "dm_id",
      sorter: (a, b) => a.dm_id - b.dm_id,
      render: (_, record) => (
        <Link
          to={`/decision-makers/${record.dm_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.dm_id}
        </Link>
      ),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
      ...getColumnSearchProps("first_name", "First Name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      sorter: (a, b) => a.last_name?.localeCompare(b.last_name),
      ...getColumnSearchProps("last_name", "Last Name"),
    },
    {
      title: "DM Type",
      dataIndex: "decision_maker_type",
      sorter: (a, b) =>
        a.decision_maker_type?.localeCompare(b.decision_maker_type),
      ...getColumnSearchProps("decision_maker_type", "Decision Maker Type"),
    },
    {
      title: "Organization",
      dataIndex: "company_name",
      sorter: (a, b) =>
        sortColumnWithNulls(a.decision_maker_type, b.decision_maker_type),
      ...getColumnSearchProps("company_name", "Organization"),
    },
    {
      title: "Title",
      dataIndex: "dm_title",
      sorter: (a, b) => sortColumnWithNulls(a.dm_title, b.dm_title),
      ...getColumnSearchProps("dm_title", "Title"),
    },
    {
      title: "QA Status",
      dataIndex: "qa_status",
      onFilter: (value, record) => record.qa_status === value,
      filters: [
        {
          text: "No QA",
          value: "no_qa",
        },
        {
          text: "Edited",
          value: "edited",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      render: (value) => formatDecisionMakerQAStatus(value, false, false),
      sorter: (a, b) => sortColumnWithNulls(a.qa_status, b.qa_status),
    },
  ];

  const onFinish = async () => {
    try {
      await submitResearchRequests(selectedDecisionMakers, dueDate, user.email);
      showSuccessMessage(
        `(${selectedDecisionMakers.length}) Decision Maker(s) were requested successfully`
      );
      setSelectedDecisionMakers([]);
      setDueDate(moment(new Date()).format(dateFormat));
    } catch (error) {
      showDangerMessage(
        "Ann error occured while requesting decision-makers for research/qa"
      );
    }
  };

  const buildDmNetworks = async () => {
    try {
      await buildNetworks(selectedDecisionMakers);
      showSuccessMessage(
        `Build network request for (${selectedDecisionMakers.length}) Decision Maker(s) was requested successfully!`
      );
      setSelectedDecisionMakers([]);
    } catch (error) {}
  };

  return (
    <Table
      dataSource={decisionMakers}
      rowKey={(record) => record.dm_id}
      rowSelection={{
        selectedRowKeys: selectedDecisionMakers,
        onChange: (selectedRowKeys, _) => {
          setSelectedDecisionMakers(selectedRowKeys);
        },
      }}
      loading={isLoading}
      columns={columns}
      pagination={{
        position: ["topRight"],
        showTotal: () => {
          return (
            <div
              style={{
                position: "absolute",
                left: 0,
                marginRight: 20,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex" }}>
                <label style={{ fontSize: 20 }}>Due Date: </label>
                <DatePicker
                  style={{ marginLeft: 20 }}
                  id="date-picker"
                  defaultValue={moment(new Date())}
                  allowClear={false}
                  onChange={(value) => {
                    setDueDate(value ? moment(value).format(dateFormat) : null);
                  }}
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, "day"))
                  }
                  format={dateFormat}
                />
              </div>

              <Popconfirm
                title={`Are you sure you want to request ${selectedDecisionMakers.length} decision maker(s) with due date ${dueDate}`}
                onConfirm={onFinish}
                okText="Yes"
                cancelText="No"
                disabled={selectedDecisionMakers.length < 1}
              >
                <Button
                  type="primary"
                  style={{ margin: "0 20px", padding: "0 25px" }}
                  disabled={selectedDecisionMakers.length < 1}
                >
                  Submit For Research/QA
                </Button>
              </Popconfirm>
              <Popconfirm
                title={`Are you sure you want to build network(s) for (${selectedDecisionMakers.length}) decision-maker(s)`}
                onConfirm={buildDmNetworks}
                okText="Yes"
                cancelText="No"
                disabled={selectedDecisionMakers.length < 1}
              >
                <Button disabled={selectedDecisionMakers.length < 1}>
                  Build Network(s)
                </Button>
              </Popconfirm>
            </div>
          );
        },
      }}
    />
  );
};

export default RequestsAdminGrid;
