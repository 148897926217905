import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  createQualityAssuranceTimestamp,
  createComprehensiveReviewTimestamp,
  getDMNetworkBuildingStatus,
  updateResearchRequestAction,
  createDecisionMakerQAReleaseLog,
  createDecisionMakerResearchReleaseLog,
} from "../../../APIClient";
import { showSuccessMessage } from "../../../utils";
import { DecisionMakerContext } from "../DecisionMakerContext";
import { ComprehensiveReviewCard } from "./ComprehensiveReviewCard";
import { QualityAssuranceCard } from "./QualityAssuranceCard";
import {
  determineComprehensiveReviewStatus,
  determineQualityAssuranceStatus,
} from "./DecisionMakerStatus";
import { ResearchRequestStatus } from "../../../constants";

export const QualityAssurance = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isDMNetworkBuilding, setIsDMNetworkBuilding] = useState(false);
  const [isDMNetworkFailed, setIsDMNetworkFailed] = useState(false);

  const {
    setUpdateDecisionMakerQAStatus,
    setComprehensiveReviewInfo,
    setQualityAssuranceInfo,
  } = useContext(DecisionMakerContext);

  const decisionMaker = useSelector((state) => state.decisionMaker);
  const userEmail = useSelector((state) => state.user.email);

  const fetchDecisionMakerIsBuildingStatus = async () => {
    setIsLoading(true);
    try {
      const response = await getDMNetworkBuildingStatus([decisionMaker.id]);
      setIsDMNetworkBuilding(response[0]?.is_building || false);
      setIsDMNetworkFailed(response[0]?.is_failed || false);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (decisionMaker.id) {
      setQualityAssuranceInfo(
        determineQualityAssuranceStatus({
          ...decisionMaker.qa_events,
          qa_status: decisionMaker.qa_status,
        })
      );
      setComprehensiveReviewInfo(
        determineComprehensiveReviewStatus(decisionMaker.qa_events)
      );
      fetchDecisionMakerIsBuildingStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMaker.id]);

  useEffect(() => {
    if (isDMNetworkBuilding) {
      const timer = setTimeout(() => {
        fetchDecisionMakerIsBuildingStatus();
      }, 10000);
      return () => clearTimeout(timer);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDMNetworkBuilding]);

  const researchRequestFulfilled = async () => {
    await updateResearchRequestAction(
      decisionMaker.id,
      userEmail,
      ResearchRequestStatus.QA
    );
    await createDecisionMakerResearchReleaseLog(decisionMaker?.id, userEmail);
    await createDecisionMakerQAReleaseLog(decisionMaker.id, userEmail);
  };
  const onQAClick = async () => {
    setIsLoading(true);
    try {
      await createQualityAssuranceTimestamp(decisionMaker.id, userEmail);
      setIsDMNetworkBuilding(true);
      setUpdateDecisionMakerQAStatus(true);
      await researchRequestFulfilled();
      showSuccessMessage("The most recent QA timestamp is updated");
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  const onReviewClick = async () => {
    setIsLoading(true);
    try {
      await createComprehensiveReviewTimestamp(decisionMaker.id, userEmail);
      setIsDMNetworkBuilding(true);
      setUpdateDecisionMakerQAStatus(true);
      await researchRequestFulfilled();
      showSuccessMessage(
        "The most recent comprehensive review timestamp is updated"
      );
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ComprehensiveReviewCard
        isDMNetworkBuilding={isDMNetworkBuilding}
        isDMNetworkFailed={isDMNetworkFailed}
        isLoading={isLoading}
        onClick={onReviewClick}
      />
      <QualityAssuranceCard
        isDMNetworkBuilding={isDMNetworkBuilding}
        isDMNetworkFailed={isDMNetworkFailed}
        isLoading={isLoading}
        onClick={onQAClick}
      />
    </>
  );
};
