import { Button, Popconfirm, Popover, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  ALL_GROUPS,
  isUserInAllowedGroups,
} from "../../../util/feature_flags/users";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { daysSince, formatTimestamp } from "../../../utils";
import { useHistory } from "react-router-dom";
import {
  createDecisionMakerQACheckoutLog,
  createDecisionMakerQAReleaseLog,
  createDecisionMakerResearchCheckoutLog,
  createDecisionMakerResearchReleaseLog,
} from "../../../APIClient";
import { getColumnSearchProps } from "../../../util/columnSearchPropFactory";
export const CreateCheckoutColumn = (isQA, queueGridData, setQueueGridData) => {
  const userEmail = useSelector((state) => state.user.email);
  const userGroups = useSelector((state) => state.userGroups);
  const history = useHistory();

  const isAllowedToRelease = (checkedOutBy) => {
    return (
      checkedOutBy === userEmail ||
      isUserInAllowedGroups(userGroups, [
        ALL_GROUPS.TECH,
        ALL_GROUPS.PRODUCT,
        ALL_GROUPS.TARGETING_ASSOCIATES,
      ])
    );
  };

  const handleQACheckout = async (decisionMakerId, rowId) => {
    try {
      const resp = await createDecisionMakerQACheckoutLog(
        decisionMakerId,
        userEmail
      );
      if (resp.id) {
        setQueueGridData(
          queueGridData.map((item) =>
            item.id === rowId
              ? {
                  ...item,
                  checkout_qa_info: { is_checked_out: true, meta_info: resp },
                }
              : item
          )
        );
        history.push(`/decision-makers/${resp.decision_maker_id}`);
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleResearchCheckout = async (decisionMakerId, rowId) => {
    try {
      const resp = await createDecisionMakerResearchCheckoutLog(
        decisionMakerId,
        userEmail
      );
      if (resp.id) {
        setQueueGridData(
          queueGridData.map((item) =>
            item.id === rowId
              ? {
                  ...item,
                  checkout_research_info: {
                    is_checked_out: true,
                    meta_info: resp,
                  },
                }
              : item
          )
        );
        history.push(`/decision-makers/${resp.decision_maker_id}`);
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleQARelease = async (decisionMakerId, rowId) => {
    try {
      const resp = await createDecisionMakerQAReleaseLog(
        decisionMakerId,
        userEmail
      );
      if (resp.id) {
        setQueueGridData(
          queueGridData.map((item) =>
            item.id === rowId
              ? {
                  ...item,
                  checkout_qa_info: { is_checked_out: false, meta_info: resp },
                }
              : item
          )
        );
      }
    } catch (error) {
      alert(error);
    }
  };
  const handleResearchRelease = async (decisionMakerId, rowId) => {
    try {
      const resp = await createDecisionMakerResearchReleaseLog(
        decisionMakerId,
        userEmail
      );
      if (resp.id) {
        setQueueGridData(
          queueGridData.map((item) =>
            item.id === rowId
              ? {
                  ...item,
                  checkout_research_info: {
                    is_checked_out: false,
                    meta_info: resp,
                  },
                }
              : item
          )
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  if (isQA === true) {
    return {
      title: "Check Out",
      key: "checkout",
      ...getColumnSearchProps("checkout_qa_info.meta_info.updated_by", "User"),
      render: (record) => {
        if (record?.checkout_qa_info?.is_checked_out === true) {
          return (
            <>
              <Popover
                content={
                  <div>
                    <p>
                      {`Checked out on `}
                      {formatTimestamp(
                        record.checkout_qa_info?.meta_info?.event_timestamp
                      )}
                      {` `}(
                      {daysSince(
                        record.checkout_qa_info?.meta_info?.event_timestamp
                      )}
                      )
                    </p>
                  </div>
                }
              >
                {record?.checkout_qa_info?.meta_info?.updated_by.replace(
                  "@applecart.co",
                  ""
                )}
              </Popover>
              {isAllowedToRelease(
                record?.checkout_qa_info?.meta_info?.updated_by
              ) && (
                <Popconfirm
                  onConfirm={() =>
                    handleQARelease(record.decision_maker_id, record.id)
                  }
                  okText="Yes"
                  cancelText="No"
                  title={`Are you sure you want to release ${record.first_name} ${record.last_name}?`}
                >
                  <Tooltip title="Release decision maker" placement="bottom">
                    <Button type="link" style={{ fontSize: "12px" }}>
                      <CloseOutlined style={{ color: "#f5222d" }} />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              )}
            </>
          );
        } else {
          return (
            <Popconfirm
              onConfirm={() =>
                handleQACheckout(record.decision_maker_id, record.id)
              }
              okText="Yes"
              cancelText="No"
              title={`Are you sure you want to check out ${record.first_name} ${record.last_name}?`}
            >
              <Button
                type="link"
                icon={<PlusCircleOutlined style={{ fontSize: 12 }} />}
              >
                Select
              </Button>
            </Popconfirm>
          );
        }
      },
    };
  }

  return {
    title: "Check Out",
    key: "checkout",
    ...getColumnSearchProps(
      "checkout_research_info.meta_info.updated_by",
      "User"
    ),
    render: (record) => {
      if (record?.checkout_research_info?.is_checked_out === true) {
        return (
          <>
            <Popover
              content={
                <div>
                  <p>
                    {`Checked out on `}
                    {formatTimestamp(
                      record.checkout_research_info?.meta_info?.event_timestamp
                    )}{" "}
                    (
                    {daysSince(
                      record.checkout_research_info?.meta_info?.event_timestamp
                    )}
                    )
                  </p>
                </div>
              }
            >
              {record?.checkout_research_info?.meta_info?.updated_by.replace(
                "@applecart.co",
                ""
              )}
            </Popover>
            {isAllowedToRelease(
              record?.checkout_research_info?.meta_info?.updated_by
            ) && (
              <Popconfirm
                onConfirm={() =>
                  handleResearchRelease(record.decision_maker_id, record.id)
                }
                okText="Yes"
                cancelText="No"
                title={`Are you sure you want to release ${record.first_name} ${record.last_name}?`}
              >
                <Tooltip title="Release decision maker" placement="bottom">
                  <Button type="link" style={{ fontSize: "12px" }}>
                    <CloseOutlined style={{ color: "#f5222d" }} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
          </>
        );
      } else {
        return (
          <Popconfirm
            onConfirm={() =>
              handleResearchCheckout(record.decision_maker_id, record.id)
            }
            okText="Yes"
            cancelText="No"
            title={`Are you sure you want to check out ${record.first_name} ${record.last_name}?`}
          >
            <Button
              type="link"
              icon={<PlusCircleOutlined style={{ fontSize: 12 }} />}
            >
              Select
            </Button>
          </Popconfirm>
        );
      }
    },
  };
};
