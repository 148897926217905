import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  createDecisionMakerQAReleaseLog,
  createDecisionMakerResearchReleaseLog,
  getActiveResearchRequests,
  getLastCheckoutLog,
} from "../../../APIClient";
import {
  ALL_GROUPS,
  isUserInAllowedGroups,
} from "../../../util/feature_flags/users";
import { RollbackOutlined } from "@ant-design/icons";
import {
  ResearchRequestAction,
  ResearchRequestStatus,
} from "../../../constants";

export const CheckoutStatus = ({ lastCheckoutLog, setLastCheckoutLog }) => {
  const decisionMaker = useSelector((state) => state.decisionMaker);
  const userEmail = useSelector((state) => state.user.email);
  const userGroups = useSelector((state) => state.userGroups);
  const [activeResearchRequest, setActiveResearchRequest] = useState([]);

  const retrieveActiveResearchRequest = async () => {
    const resp = await getActiveResearchRequests(decisionMaker?.id);
    setActiveResearchRequest(resp);
  };

  const retrieveLastCheckoutLog = async () => {
    const resp = await getLastCheckoutLog(decisionMaker?.id);
    setLastCheckoutLog(resp);
  };

  const isAllowedToRelease = () => {
    if (
      lastCheckoutLog === null ||
      lastCheckoutLog?.action !== ResearchRequestAction.CHECKOUT
    ) {
      return false;
    } else {
      return (
        lastCheckoutLog?.updated_by === userEmail ||
        isUserInAllowedGroups(userGroups, [
          ALL_GROUPS.TECH,
          ALL_GROUPS.PRODUCT,
          ALL_GROUPS.TARGETING_ASSOCIATES,
        ])
      );
    }
  };

  const isRequestCheckout = () => {
    if (activeResearchRequest === null || activeResearchRequest?.length === 0) {
      return false;
    }
    return (
      lastCheckoutLog !== null &&
      lastCheckoutLog?.action === ResearchRequestAction.CHECKOUT
    );
  };

  const userName = () => {
    return lastCheckoutLog?.updated_by.replace("@applecart.co", "");
  };
  const checkoutMessage = () => {
    return `${userName()} checkedout`;
  };

  const statusMessage = () => {
    const process = lastCheckoutLog?.process.toUpperCase();
    const eventTimeInUtc = lastCheckoutLog?.event_timestamp + "z";
    const event_day = new Date(eventTimeInUtc).toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    });
    const event_time = new Date(eventTimeInUtc).toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
    });
    return `${process} started by ${userName()} on ${event_day} at ${event_time}`;
  };

  const handleUserAction = async () => {
    if (
      lastCheckoutLog !== null &&
      lastCheckoutLog?.action === ResearchRequestAction.CHECKOUT
    ) {
      if (lastCheckoutLog?.process === ResearchRequestStatus.RESEARCH) {
        await createDecisionMakerResearchReleaseLog(
          decisionMaker?.id,
          userEmail
        );
      } else if (lastCheckoutLog?.process === ResearchRequestStatus.QA) {
        await createDecisionMakerQAReleaseLog(decisionMaker?.id, userEmail);
      }
      setLastCheckoutLog(null);
    }
  };

  useEffect(() => {
    retrieveActiveResearchRequest();
    retrieveLastCheckoutLog();
    // eslint-disable-next-line
  }, [decisionMaker]);

  return (
    isRequestCheckout() && (
      <Row gutter={[10, 0]}>
        <Col className="gutter-row">
          <Tooltip title={statusMessage()}> {checkoutMessage()}</Tooltip>
        </Col>
        <Col>
          <Tooltip title={"release"}>
            <Popconfirm
              title={"Are you sure you want to release this DM?"}
              onConfirm={handleUserAction}
              okText="Yes"
              cancelText="Cancel"
              disabled={!isAllowedToRelease()}
            >
              <Button
                disabled={!isAllowedToRelease()}
                icon={<RollbackOutlined />}
                color="green"
              />
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
    )
  );
};
